<template>
  <b-custom-tabs id="LeadsTabs" :tabs="tabs" lazy />
</template>

<script>
export default {
  data() {
    return {
      tabs: [{ title: "Görüşmeler" }, { title: "Müşteri Bilgileri" }, { title: "Notlar" }],
    };
  },
};
</script>

<style></style>
